section.one {
  margin: 0 auto;
}

section.one .top {
  margin-bottom: 30px;
}

section.one a.google {
  border: 1px solid rgba(var(--charcoal-rgb), .1);
  color: rgba(var(--charcoal-rgb), .5);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background: none;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  display: flex;
}

section.one a.google:focus, section.one a.google:hover {
  border: 1px solid rgba(var(--blue-rgb), 1);
  color: rgba(var(--charcoal-rgb), 1);
}

section.one a.google:focus {
  background: rgba(var(--blue-rgb), .2);
}

section.one a.google img {
  width: 5%;
  margin: 0;
}

section.one a.google .left {
  flex-basis: 90%;
  align-items: center;
  gap: 10px;
  display: flex;
}

section.one .or {
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
  display: flex;
  position: relative;
}

section.one .or:before {
  content: "";
  background: rgba(var(--charcoal-rgb), .1);
  width: 40%;
  height: 1px;
  position: absolute;
  top: 55%;
  right: 0;
}

section.one .or:after {
  content: "";
  background: rgba(var(--charcoal-rgb), .1);
  width: 40%;
  height: .1px;
  position: absolute;
  top: 55%;
  left: 0;
}

section.one .forgot {
  float: right;
  margin-top: 5px;
  display: block;
}

section.one .signup {
  text-align: center;
  margin-top: 20px;
}

@media only screen and (width >= 100px) {
  section.one {
    width: 90%;
  }
}

@media only screen and (width >= 768px) {
  section.one {
    width: 50%;
  }
}
/*# sourceMappingURL=login.10b14142.css.map */
