@import url('./global.css');

section.one {
   margin: 0 auto;
}

section.one .top {
   margin-bottom: 30px;
}

section.one a.google {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: justify;
   -ms-flex-pack: justify;
   justify-content: space-between;
   gap: 10px;
   background: transparent;
   border: 1px solid rgba(var(--charcoal-rgb), 0.1);
   color: rgba(var(--charcoal-rgb), 0.5);
   -webkit-transition: var(--transition);
   transition: var(--transition);
}

section.one a.google:focus,
section.one a.google:hover {
   border: 1px solid rgba(var(--blue-rgb), 1);
   color: rgba(var(--charcoal-rgb), 1);
}

section.one a.google:focus {
   background: rgba(var(--blue-rgb), 0.2);
}

section.one a.google img {
   width: 5%;
   margin: 0;
}

section.one a.google .left {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   gap: 10px;
   -ms-flex-preferred-size: 90%;
   flex-basis: 90%;
}

section.one .or {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
   gap: 10px;
   margin: 20px 0;
   position: relative;
}

section.one .or::before {
   content: '';
   position: absolute;
   width: 40%;
   height: 1px;
   top: 55%;
   right: 0;
   background: rgba(var(--charcoal-rgb), 0.1);
}

section.one .or::after {
   content: '';
   position: absolute;
   width: 40%;
   height: 0.1px;
   top: 55%;
   left: 0;
   background: rgba(var(--charcoal-rgb), 0.1);
}

section.one .forgot {
   display: block;
   margin-top: 5px;
   float: right;
}

section.one .signup {
   text-align: center;
   margin-top: 20px;
}

/* styles for extra small devices (phones, >=100px) */
@media only screen and (min-width: 100px) {
   section.one {
      width: 90%;
   }
}

/* styles for medium devices (tablets, >=768px) */
@media only screen and (min-width: 768px) {
   section.one {
      width: 50%;
   }
}

/* styles for large devices (desktops, >=992px) */
@media only screen and (min-width: 992px) {
}

/* styles for extra large devices (large desktops, >=1200px) */
@media only screen and (min-width: 1200px) {
}

/* styles for extra extra large devices (full-screen monitors, >=1400px) */
@media only screen and (min-width: 1400px) {
}
